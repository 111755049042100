let map = {
  //BBG prod MAL-1560
  'mkjs.apwyd1eu6b99.xyz': {
    title: 'A3直播',
    api: 'x7eidtry.aps41jqzv994.xyz:2053/api',
    ws: 'im.aps41jqzv994.xyz:2053/ws/',
    uploadUrl: 'x7eidtry.aps41jqzv994.xyz:2053/api?method=upload',
    downloadUrl: 'file.aps41jqzv994.xyz:2053/',
    brand: 'bbg'
  },
  'admin.bbg-prod.landingmakljdh.xyz': {
    title: 'A3直播',
    api: 'x7eidtry.ap6rnmlqrk87.xyz/api',
    ws: 'im.ap6rnmlqrk87.xyz/ws/',
    uploadUrl: 'x7eidtry.ap6rnmlqrk87.xyz/api?method=upload',
    downloadUrl: 'file.ap6rnmlqrk87.xyz/',
    brand: 'bbg'
  },
  // BBG prod MAL-1559
  'snn.200925aaa.xyz': {
    title: 'A3直播',
    api: 'x7eidtry.ap6rnmlqrk87.xyz/api',
    ws: 'im.ap6rnmlqrk87.xyz/ws/',
    uploadUrl: 'x7eidtry.ap6rnmlqrk87.xyz/api?method=upload',
    downloadUrl: 'file.ap6rnmlqrk87.xyz/',
    brand: 'bbg'
  },
  'admin.apmf2ardvl120.xyz': {
    title: 'A3直播 UAT',
    api: 'api-admin.apmf2ardvl120.xyz/api',
    ws: 'im.apmf2ardvl120.xyz/ws/',
    uploadUrl: 'api-admin.apmf2ardvl120.xyz/api?method=upload',
    downloadUrl: 'file.apmf2ardvl120.xyz/',
    brand: 'bbg'
  },
  'admin.aptehmhr2u109.xyz': {
    title: 'A3直播 UAT',
    api: 'api-admin.aptehmhr2u109.xyz/api',
    ws: 'im.ap6rnmlqrk87.xyz/ws/',
    uploadUrl: 'api-admin.aptehmhr2u109.xyz/api?method=upload',
    downloadUrl: 'file.ap6rnmlqrk87.xyz/',
    brand: 'bbg'
  }
};

let h = window.location.host;
console.log('location.host', h);

let m = map[h];
if (!m) {
  m = map['snn.200925aaa.xyz'];
}

let brand = m.brand;
let baseUrl = `https://${m.api}`;
let websocketUrl = `wss://${m.ws}`;
let uploadUrl = `https://${m.uploadUrl}`;
let downloadUrl = `https://${m.downloadUrl}`;

let routerMode = 'hash';
let version = '2.11.5';
let verIndex = '20110005';
let channel = 'manage';
let timeout = 30000;

// console.log(`baseUrl: ${baseUrl}`);
// console.log(`websocketUrl: ${websocketUrl}`);
// console.log(`uploadUrl: ${uploadUrl}`);
// console.log(`downloadUrl: ${downloadUrl}`);

let iszbFriend = brand == 'h' ? false : true;
if (process.env.NODE_ENV == 'development') {
  timeout = 120000;
}

export {
  baseUrl,
  routerMode,
  websocketUrl,
  uploadUrl,
  downloadUrl,
  version,
  brand,
  verIndex,
  channel,
  timeout,
  iszbFriend
};
